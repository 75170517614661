<template>
    <div class="w-100">
        <Header />
        <!-- Setting menu for desktop mod @start-->
        <b-row class="p-lg-3 m-0 mb-3 mt-6 ">
            <b-col style="min-height: 55vh;max-height: fit-content;" class="zc-container px-3 is-desktop" cols="12" col
                lg="3" md="12" sm="12">
                <div @click="changeMenu(m)" v-for="(m, i) in     settingMenu    " :key="i"
                    :style="activeTab !== m.menu ? `opacity:.5; ${m?.style}` : `opacity:1; ${m?.style}`"
                    :class="{ 'sl-bg-black-a93': activeTab === m.menu }" class="sl-container mt-2 p-3">
                    <img :src="m.icon" width="19px" height="19px" class="img-fluid" />
                    <span>{{ $gettext(m.menu) }}</span>
                </div>
                <div @click="Logout"
                    :style="activeTab !== 'Logout' ? `opacity:.5; color:#E80000;` : `opacity:1; color:#E80000;`"
                    :class="{ 'sl-bg-black-a93': activeTab === 'Logout' }" class="sl-container mt-2 p-3">
                    <img src="@/assets/zaajira-candidate/assets/icons/logout.svg" width="19px" height="19px"
                        class="img-fluid" />
                    <span>{{ $gettext("Logout") }}</span>
                </div>
                <div @click="DeleteAccount"
                    :style="activeTab !== 'Delete account' ? `opacity:.5; color:#E80000;` : `opacity:1; color:#E80000;`"
                    :class="{ 'sl-bg-black-a93': activeTab === 'Delete account' }" class="sl-container mt-2 p-3">
                    <img src="@/assets/zaajira-candidate/assets/icons/delete_user.svg" width="19px" height="19px"
                        class="img-fluid" />
                    <span>{{ $gettext("Delete_account") }}</span>
                </div>
            </b-col>
            <b-col cols="12" col lg="9" md="12" sm="12">
                {{ getActiveTab() }}
                <div id="setting-btn" @click="openBottomSheet" v-if="isMobileMenu"
                    class="sl-bg-black-a93 sl-container my-2 p-3">
                    <img :src="activeIcon" width="19px" height="19px" class="img-fluid" />
                    <div class="d-flex justify-content-between w-100">
                        <!-- <span>{{ activeTab }}</span> -->
                        <span>{{ $gettext(activeTab) }}</span>

                        <i style="font-size: 1.5rem;" class="fa fa-angle-down" aria-hidden="true"></i>
                    </div>
                </div>

                <div style="max-height: fit-content;min-height: 55vh;" class="zc-container">
                    <router-view></router-view>
                </div>
            </b-col>
        </b-row>
        <!-- Setting menu for desktop mod @end-->

        <!-- Setting menu for Mobile mod @start-->
        <zcBottomSheet :isOpen="bottomSheetOpen" @close="closeBottomSheet" customStyle="overflow:auto;top: auto;"
            customClass="">
            <!-- Content for the Bottom Sheet -->
            <div slot="child">
                <div @click="changeMenu(m)" v-for="(m, i) in     settingMenu    " :key="i"
                    :class="{ 'sl-bg-black-a93': activeTab === m.menu }" class="sl-container mt-2 p-3">
                    <img :src="m.icon" width="19px" height="19px" class="img-fluid" />
                    <span>{{ $gettext(m.menu) }}</span>
                </div>
                <div @click="DeleteAccount" style="color:#E80000;"
                    :class="{ 'sl-bg-black-a93': activeTab === 'Delete account' }" class="sl-container mt-2 p-3">
                    <img src="@/assets/zaajira-candidate/assets/icons/delete_user.svg" width="19px" height="19px"
                        class="img-fluid" />
                    <span>{{ $gettext("Delete_account") }}</span>
                </div>
                <div @click="Logout" :class="{ 'sl-bg-black-a93': activeTab === 'Logout' }"
                    class="sl-container mt-2 p-3 justify-content-center">

                    <span>{{ $gettext("Logout") }}</span>
                </div>
            </div>
        </zcBottomSheet>
        <!-- Setting menu for Mobile mod @end-->

        <zcModal :is-open="islogoutModal" @open="islogoutModal = true" @close="islogoutModal = false"
            cumtomeStyle="width:22rem;">
            <template v-slot:child>
                <div class="">
                    <h2 class="zc-modal-header">{{ $gettext("Logging_out") }}</h2>
                    <p class="zc-modal-para">
                        {{ $gettext("Are_you_sure_you_want_to_log_out") }}
                    </p>
                    <div class="col-12 mt-4 btn-flex-fd-row-jc-sb">
                        <span @click="islogoutModal = false" class="go-back">{{ $gettext("No") }}</span>
                        <b-button variant="primary" size="sm" @click="LogoutUser">{{ $gettext("Logout") }}</b-button>
                    </div>
                </div>
            </template>
        </zcModal>
        <!-- delete account @start -->
        <zcModal :is-open="isDeleteModal" @open="isDeleteModal = true" @close="isDeleteModal = false">
            <template v-slot:child>
                <div class="">
                    <h2 class="zc-modal-header">{{ $gettext("Delete_account") }}</h2>
                    <p class="zc-modal-para">{{ $gettext("Are_you_sure_you_want_to_delete_your_account") }}</p>
                    <div class="col-12 mt-4 btn-flex-fd-row-jc-sb">
                        <span @click="isDeleteModal = false" class="go-back">{{ $gettext("No") }}</span>
                        <b-button variant="primary" size="sm" @click="deleteUserAccount">{{ $gettext("Yes_delete")
                        }}</b-button>
                    </div>
                </div>
            </template>
        </zcModal>
        <!-- delete account @end -->

    </div>
</template>

<script>
import Header from '../../../components/Partials/Header.vue'
import password from '@/assets/zaajira-candidate/assets/icons/password.svg'
import language from '@/assets/zaajira-candidate/assets/icons/language.svg'
import help from '@/assets/zaajira-candidate/assets/icons/help.svg'

import person from '@/assets/zaajira-candidate/assets/icons/person.svg'
import industry from '@/assets/zaajira-candidate/assets/icons/industry.svg'
import skills from '@/assets/zaajira-candidate/assets/icons/skills.svg'
import personal_details from '@/assets/zaajira-candidate/assets/icons/personal_details.svg'
// import logout from '@/assets/zaajira-candidate/assets/icons/logout.svg'
// import delete_user from '@/assets/zaajira-candidate/assets/icons/delete_user.svg'
import zcModal from '../../../components/Modal/Modal.vue'
import zcBottomSheet from '../../../components/Modal/BottomSheet.vue'

import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import { formattedNumber, convertToTimeSince } from '../Helper/HelperFunctions.js'
import { IMAGEURL } from "@/config";
import avtar from '@/assets/zaajira-candidate/assets/bg-images/avtar-image.png'
export default {
    name: "Settings",
    components: { Header, zcModal, zcBottomSheet },
    data() {
        return {

            isMobileMenu: window.innerWidth <= 992,
            activeTab: "Personal detail",
            activeIcon: person,
            settingMenu: [

                // profile settings @start
                { menu: "Personal_detail", path: 'personal-detail', icon: person, },
                { menu: "Industries", path: 'edit-industry', icon: industry, },
                { menu: "Skills", path: 'edit-skill', icon: skills, },
                { menu: "Professional_detail", path: 'professional-details', icon: personal_details, },
                // profile settings @end
                { menu: "Change_password", path: 'change-password', icon: password, },
                { menu: "Change_language", path: 'change-language', icon: language, },
                { menu: "Support", path: 'support', icon: help, },
                // { menu: "Logout", path: 'logout', icon: logout, style: 'color:#E80000;' },
                // { menu: "Delete account", path: 'delete-account', icon: delete_user, style: 'color:#E80000;' },
            ],
            islogoutModal: false,
            isDeleteModal: false,
            bottomSheetOpen: false,
            isMobileMenu: window.innerWidth <= 992,
        };
    },

    methods: {
        getActiveTab() {
            // console.log('this.$route.name', this.$route.path);

            // console.log('aciveTab', this.activeTab);
            if (this.$route.path === '/candidate/settings') {
                this.activeTab = "Personal detail"
            }
            else {
                this.activeTab = this.settingMenu.filter((m) => this.$route.path === `/candidate/settings/${m.path}`).map((m) => { return m.menu })[0]
                this.activeIcon = this.settingMenu.filter((m) => this.$route.path === `/candidate/settings/${m.path}`).map((m) => { return m.icon })[0]
            }
        },
        changeMenu(m) {

            this.activeTab = m.menu;
            this.activeIcon = m.icon;
            this.$router.push({ path: '/candidate/settings/' + m.path });
            this.bottomSheetOpen = false
        },
        Logout() {
            this.activeTab = 'Logout'
            this.islogoutModal = true
        },
        LogoutUser() {
            const payload = {};
            this.$store.dispatch("onLogout");
            this.islogoutModal = false
            this.$toasted.show(`Logout successfully`, {
                duration: 6000,
                type: "success",
                icon: "check",
            });
        },
        DeleteAccount() {
            this.activeTab = 'Delete account'
            this.isDeleteModal = true
        },
        deleteUserAccount() {
            this.isDeleteModal = false
            CandidateService.DeleteUserAccount()
                .then((res) => {

                    console.log('DeleteUserAccount::res', res.data.data);
                    this.$toasted.show(`${res.data.data}`, {
                        duration: 6000,
                        type: "success",
                        icon: "check",
                    });
                    setTimeout(() => {
                        this.LogoutUser();
                    }, 1000)

                })
                .catch((error) => {
                    console.log(error);
                })

        },
        openBottomSheet() {
            this.bottomSheetOpen = true;
        },
        closeBottomSheet() {
            this.bottomSheetOpen = false;
        },
        handleClickOutside(e) {
            if (this.bottomSheetOpen) {
                // console.log('e.target', e.target);
                // console.log('e.target', e.target.className);
                // console.log('constbtContent', constbtContent);
                // console.log('settingBtn', settingBtn);
                // console.log('   this.$el.contains(settingBtn)', e.target.contains(settingBtn));
                // console.log('btn', btn);
                // console.log('settingBtn', settingBtn.contains(e.target));
                const constbtContent = document.getElementById('bt-content')
                const settingBtn = document.getElementById('setting-btn')
                const btn = e.target.contains(settingBtn) || settingBtn.contains(e.target)
                if (!btn || e.target.className === 'zcBottomSheet' && !e.target.contains(constbtContent)) {
                    this.hideMenu()
                }
            }
        },
        hideMenu: function () {
            this.bottomSheetOpen = false
        },
    },
    computed: {

    },
    created() {
        // addEventListener("resize", (e) => {
        //     this.isMobileMenu = window.innerWidth <= 992
        // });   
    },
    beforeMount() {
        this.isMobileMenu = window.innerWidth <= 992

    },
    mounted() {
        window.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        window.removeEventListener("click", this.handleClickOutside);
    },


}
</script>

<style></style>